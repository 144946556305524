// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  envName: 'staging',
  isAltVersion: false,
  production: true,
  baseUrl: 'https://preprod.zele.nextep-health.com',
  apiBaseUrl: 'https://api.preprod.zele.nextep-health.com',
  storagePath: 'https://api.preprod.zele.nextep-health.com/storage',
  tokenName: 'zeleShopToken',
  tokenWhitelistedDomains: ['api.preprod.zele.nextep-health.com'],
  enableBugsnag: true,
  bugsnagApiKey: 'd11732012ca18ec602ffb0e1823d6aa7'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error' // Included with Angular CLI.
